import React from 'react';
import Layout from 'components/Layout';

const MentionsPage = () => {

  let year = new Date();
  return (
    <Layout pageName="Mentions">
      <div className="about-container">
        <div className="about-container-text" >
          <h2>Droits d'auteur</h2>
          <p>
            L’ensemble des textes et images présents sur le site www.mannani.fr 
            sont la propriété exclusive de Marianne Mannani et de ses clients.

            Aucune exploitation, utilisation, modification, reproduction (totale ou partielle), 
            diffusion ou rediffusion ne peut être faite sans l’accord de Marianne Mannani 
            conformément à l’article L.122-4 du Code de la propriété intellectuelle.
          </p>
          <h2>Crédits</h2>
          <ul className="about-container-list" >
            <li className="about-container-list-item">Design du site</li>
            <li className="about-container-list-item">Développement</li>
            <li className="about-container-list-item">Prises de vues</li>
          </ul>
          <p> ↳ ©Marianne Mannani</p>
          <h2>Responsable d'édition</h2>
          <p>Marianne Mannani, membre de la Maison de l'Initiative,
            SCOP SA à capital variable dont le siège social est situé au 52 rue Jacques Babinet - 31100 Toulouse, 
            immatriculée au Registre du Commerce et des Sociétés de Toulouse (n°398 386 102)
          </p>
          <p>N° TVA intra-comunautaire : FR 75 398 386 102</p>
          <p className="about-container-rubrique">contact Maison de l'initiative : </p>
            <ul className="about-container-list" >
              <li className="about-container-list-item">
                <a className="about-container-link" href="tel:+534638110">
                  05 34 63 81 10
                </a>
              </li>
              <li className="about-container-list-item">
                <a className="about-container-link" href="mailto:cae@maisoninit.coop">
                  cae@maisoninit.coop
                </a>
              </li>
              <li className="about-container-list-item">
                <a className="about-container-link" target="_blank" rel="noopener noreferrer" href="https://www.maison-initiative.org">
                  www.maison-initiative.org
                </a>
              </li>
            </ul>
          <p>Hébergement : Netlify (
            <a className="about-container-link" target="_blank" rel="noopener noreferrer" href="https://www.netlify.com/">
              www.netlify.com
            </a>
          )</p>
          <p>{`Copyright ${year.getFullYear()}`}</p>
        </div>
      </div>
    </Layout>
  );
};

export default MentionsPage;